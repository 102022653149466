import { mainDiv, mainTitle, secondaryTitle, columnsContainer, leftColumn, rightColumn, pText } from './DessertRecipes.module.css'
import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import RecipeRightBar from "../../components/RecipeRightBar/RecipeRightBar"
import RecipesListByNumber from "./RecipesListByNumber"

function buildRecipesMap(recipesList) {
    var retval = {};

    for (var i = 0; i < recipesList.length; i++){
        var currentRecipe = recipesList[i];
        retval[currentRecipe.recipeNumber] = currentRecipe;
    }

    return retval;
}   

const DessertRecipes = () => {
    const data  = useStaticQuery(graphql`
    {
        allMysqlAllRecipes(filter: {recipeNumber: {in: [177,60,113,120,154,155,65,93,53,54,57,55,52,117,97,45,42,18,124,68,33]}}) {
            nodes {
              recipeNumber
              image {
                childImageSharp {
                  gatsbyImageData(width: 400, placeholder: BLURRED)
                }
              }
              shortName
              introText
              category
              pageUrl
              rate
            }
          }
      }      
    `)
    
    const recipesMap = buildRecipesMap(data.allMysqlAllRecipes.nodes);
    const enVasitos = [177, 60, 113];
    const conLecheCondensada = [120, 154, 155];
    const deChocolate = [65, 93, 53];
    const deLimon = [54, 57, 55];
    const conDulceDeLeche = [52, 117, 97];
    const deManzana = [45, 42, 18];
    const sinHorno = [124, 68, 33];

    return (
        <div className={mainDiv}>
            <div className={columnsContainer}>
                <div className={leftColumn}>
                    <h1 className={mainTitle}>Recetas de Postres</h1>
                    <p className={pText}>Acá vas a aprender como hacer postres caseros de una forma muy fácil, rápida y económica. Estas recetas las podes preparar con pocos ingredientes y como son postres cortos, los vas a hacer en muy poco tiempo.</p>
                    <p className={pText}>Vamos a usar ingredientes básicos de casa como dulce de leche, chocolate, oreo, vainilla, leche, limón, manzana, banana o frutillas realizando preparaciones muy fáciles y rápidas, algunas para muchas personas y otras van a ser postres en vasitos o postre individual.</p>

                    <h2 className={secondaryTitle}>Postres en vasitos</h2>
                    <p className={pText}>Acá tenes algunos postres en vasitos fáciles y rápidos para preparar en tu casa. Estas recetas se preparan en vasos plásticos y son sin horno, también se pueden servir directamente en shots para ser porciones más chicas.</p>
                    <RecipesListByNumber numbersList={enVasitos} recipesMap={recipesMap}/>               

                    <h2 className={secondaryTitle}>Postres con leche condensada</h2>
                    <p className={pText}>No te quedes sin ideas de postres con leche condensada fáciles, acá tenes algunos.</p>
                    <RecipesListByNumber numbersList={conLecheCondensada} recipesMap={recipesMap}/>  
                    
                    <h2 className={secondaryTitle}>Postres de chocolate</h2>
                    <p className={pText}>Estas recetas de postres con chocolate te van a encantar para acompañar un buen desayuno.</p>
                    <RecipesListByNumber numbersList={deChocolate} recipesMap={recipesMap}/>  

                    <h2 className={secondaryTitle}>Postres de Limon</h2>
                    <p className={pText}>Acá podes aprender la receta clásica de pay de limón o pie de limón y otras recetas fáciles dulces para preparar en casa.</p>
                    <RecipesListByNumber numbersList={deLimon} recipesMap={recipesMap}/>  

                    <h2 className={secondaryTitle}>Postres con dulce de leche</h2>
                    <p className={pText}>El dulce de leche es un producto lácteo muy usado en la pastelería, acá tenes algunas recetas dulces muy fáciles de preparar.</p>
                    <RecipesListByNumber numbersList={conDulceDeLeche} recipesMap={recipesMap}/>  

                    <h2 className={secondaryTitle}>Postres de manzana</h2>
                    <p className={pText}>Estos postres con manzana son muy simples de hacer, tenés que probarlos.</p>
                    <RecipesListByNumber numbersList={deManzana} recipesMap={recipesMap}/>  

                    <h2 className={secondaryTitle}>Postres sin Horno</h2>
                    <p className={pText}>Acá tenes una selección de recetas de postres fáciles sin horno para que prepares en tu casa.</p>
                    <RecipesListByNumber numbersList={sinHorno} recipesMap={recipesMap}/>  
                </div>
                <div className={rightColumn}>
                    <RecipeRightBar></RecipeRightBar>
                </div>
            </div>
        </div>
    )
}

export default DessertRecipes