import { mainDiv } from './RecipesListByNumber.module.css'
import React from "react"
import DishCard from "../../components/HomeDishes/DishCard"
import { getImage } from "gatsby-plugin-image"

const RecipesListByNumber = ({numbersList, recipesMap}) => {
    return (
        <div className={mainDiv}>
            { numbersList.map((recipeNumber) => {
                const recipe = recipesMap[recipeNumber];
                const image = getImage(recipe.image.childImageSharp);
                return (
                    <DishCard key={recipe.id} pageUrl={recipe.pageUrl} image={image} name={recipe.shortName} shortText={recipe.introText} category={recipe.category} rate={recipe.rate} slimMargin={true}></DishCard>
                )
                })
            }
        </div>
    )
}

export default RecipesListByNumber